import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'select2/dist/js/select2.min.js';

// sticky header variable
var shrinkHeader = 120;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // Initiate Load More for Portfolio
    $(document).ready(function () {
      $('.portfolio-item-wrp').slice(0, 12).show();
      if ($('.portfolio-item-wrp:hidden').length != 0) {
        $('#loadMorePortfolio').show();
      } else {
        $('#loadMorePortfolio').hide();
      }
      $('#loadMorePortfolio').on('click', function (e) {
        e.preventDefault();
        $('.portfolio-item-wrp:hidden').slice(0, 6).slideDown();
        if ($('.portfolio-item-wrp:hidden').length == 0) {
          $('#loadMorePortfolio').text('No More to view').fadeOut('slow');
        }
      });
    });
    // Initiate Load More for Portfolio

    // Initiate Load More for Instagram Feed
    $(document).ready(function () {
      $('.instagram-item').slice(0, 18).show();
      if ($('.instagram-item:hidden').length != 0) {
        $('#loadMoreInstagram').show();
      } else {
        $('#loadMoreInstagram').hide();
      }
      $('#loadMoreInstagram').on('click', function (e) {
        e.preventDefault();
        $('.instagram-item:hidden').slice(0, 9).slideDown();
        if ($('.instagram-item:hidden').length == 0) {
          $('#loadMoreInstagram').text('No More to view').fadeOut('slow');
        }
      });
    });
    // Initiate Load More for Instagram Feed

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function () {
      $('body, .mobile-burger-icon').toggleClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
      $(this)
        .find('> .sub-menu-wrap > .sub-menu')
        .prepend(
          '<div class="return-link">' + $(this).find('> a').text() + '</div>'
        );
      $(this)
        .find('> a')
        .click(function (e) {
          e.preventDefault();
          console.log(this);
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // level 2
    $(
      '.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children'
    ).each(function () {
      $(this)
        .find('> .sub-menu-wrap > .sub-menu')
        .prepend(
          '<div class="return-link">' + $(this).find('> a').text() + '</div>'
        );
      $(this)
        .find('> a')
        .click(function (e) {
          e.preventDefault();
          console.log(this);
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // return link click
    $('.return-link').each(function () {
      $(this).click(function () {
        $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());

    formScripts();
    fullWidthVideoPlayer();
    videoPopupPlayer();
    delayHomeBannerVideo();
    footerLogoSlider();
  },
};

$(window).on('resize', function () {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp, .cards-section.v2 .section-inner-wrp .cards-wrp, .cards-section.v3 .section-inner-wrp .cards-wrp, .gallery-section.v1 .section-inner-wrp .gallery-wrapper, .footer .footer-icons .icons-wrp .icons-slider'
  ).flickity('resize');
});

$(window).on('load', function () {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp, .cards-section.v2 .section-inner-wrp .cards-wrp, .cards-section.v3 .section-inner-wrp .cards-wrp, .gallery-section.v1 .section-inner-wrp .gallery-wrapper, .footer .footer-icons .icons-wrp .icons-slider'
  ).flickity('resize');
});

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  $('.form-dropdown').select2({
    dropdownParent: $('.select2-wrapper'),
  });

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){

      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var videoSrc;
  $('.video-iframe-thumbnail').click(function () {
    videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
    $('#video').attr(
      'src',
      videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0'
    );
  });

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src', videoSrc);
  });
}
// Popup Video Player for Two Column Section

// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video

function footerLogoSlider() {
  $('.footer .footer-icons .icons-wrp .icons-slider'
  ).flickity({
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    autoPlay: true,
    pauseAutoPlayOnHover: true,
  });
}