import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    videoPopupPlayer();
    aboutUsGallerySlider();
    testimonialSlider();
  },
};

$(window).on('resize', function () {
  $('.home-testimonials-section .testimonial-wrp .testimonial-item-wrp').flickity('resize');
});

$(window).on('load', function () {
  $('.home-testimonials-section .testimonial-wrp .testimonial-item-wrp').flickity('resize');
});

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var videoSrc;
  $('.home-video-iframe-thumbnail').click(function () {
    videoSrc = $(this).data('src');
  });

  $('#my-home-modal').on('shown.bs.modal', function () {
    console.log('shown');
    $('#home-popup-video').attr('src',videoSrc);
  });

  $('#my-home-modal').on('hide.bs.modal', function () {
    $('#home-popup-video').attr('src','');
  });
}
// Popup Video Player for Two Column Section

function aboutUsGallerySlider() {
  var flkty = new Flickity('.home-about-section .gallery-one .gallery-item-wrp', {
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    autoPlay: 3000,
  });

  var carouselStatus = document.querySelector('.home-about-section .gallery-one .slider-count');

  function updateStatus1() {
    var slideNumber = flkty.selectedIndex + 1;
    carouselStatus.textContent = slideNumber + '/' + flkty.slides.length;
  }

  updateStatus1();

  flkty.on('select', updateStatus1);

  var flkty2 = new Flickity('.home-about-section .gallery-two .gallery-item-wrp', {
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    autoPlay: 3000,
  });

  var carouselStatus2 = document.querySelector('.home-about-section .gallery-two .slider-count');

  function updateStatus2() {
    var slideNumber2 = flkty2.selectedIndex + 1;
    carouselStatus2.textContent = slideNumber2 + '/' + flkty2.slides.length;
  }

  updateStatus2();

  flkty2.on( 'select', updateStatus2 );
}

function testimonialSlider() {
  $('.home-testimonials-section .testimonial-wrp .testimonial-item-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
  });

  $('.home-testimonials-section .testimonial-wrp .testimonial-item-wrp .flickity-button').appendTo($('.home-testimonials-section .testimonial-wrp .testimonial-inner-wrp'));
}